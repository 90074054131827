<template>
  <div class="search-results">
    <div class="search-results__main">
      <div class="search-results__main-greeting">
        {{ $t(`${langPath}.allSet`) }}
      </div>
      <div class="search-results__main-header">
        <h1>
          <div>
            {{ $t(`${langPath}.title.analysedProfiles`) }}
            <span class="red-text">
              {{ $t(`${langPath}.title.thirteenThousand`) }}
            </span>
          </div>
          <div>
            {{ $t(`${langPath}.title.bestMatch`) }}
          </div>
        </h1>
      </div>

      <img
        class="search-results__main-image"
        data-test="image"
        :src="imgOnboardingPerson"
        :alt="$t(`${langPath}.altImage`)"
      >

      <div class="search-results__main-button">
        <ev-button
          data-test="search-results-link"
          @click="completeOnboarding"
        >
          {{ $t(`${langPath}.showProfessionals`) }}
        </ev-button>
      </div>
    </div>
  </div>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import imgOnboardingPerson from '@/assets/images/onboarding/person-catching-talent.svg'
import { createNamespacedHelpers } from 'vuex'
import { SIGNUP } from '@/store/namespaces'
import onboarding from '@/repository/companies-app/onboarding'

const signupHelper = createNamespacedHelpers(SIGNUP)

export default {
  name: 'SearchResults',
  components: {
    EvButton
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...signupHelper.mapState(['searchUrl']),
    imgOnboardingPerson () {
      return imgOnboardingPerson
    }
  },
  methods: {
    completeOnboarding () {
      onboarding.completeOnboarding()
      window.location.assign(this.searchUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@revelotech/everestV2/tx_overline.scss";

  .red-text {
    color: var(--tx-red);
  }

  .search-results {
    background: var(--bg-red-light-03);
    height: 100%;

    &__main {
      padding: $base*10 $base*10 $base*10 $base*10;

      @include breakpoint(lg) {
        padding: $base*18 $base*10 $base*10 $base*10;
      }

      &-greeting {
        @extend %tx-overline;
      }

      &-header {
        font: var(--tx-title-1);
        margin-top: $base*8;
      }

      &-button {
        margin-top: $base*10;

        @include breakpoint(lg) {
          width: $base*50;
        }
      }

      &-image {
        height: auto;
        margin-top: $base*8;
        max-width: 100%;
      }
    }
  }
</style>
